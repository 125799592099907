// Generated by Framer (a7ecdf4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cI1hBIwZD"];

const serializationHash = "framer-nJWwn"

const variantClassNames = {cI1hBIwZD: "framer-v-124eo04"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({bGCOLOR, height, id, label, width, ...props}) => { return {...props, HR6VvY__G: label ?? props.HR6VvY__G ?? "SECTION NAME", SJkB94Ng6: bGCOLOR ?? props.SJkB94Ng6 ?? "var(--token-afc428eb-83f4-4256-aa82-c07d2f223ccd, rgb(245, 245, 245)) /* {\"name\":\"Neutral-100\"} */"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;bGCOLOR?: string;label?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, SJkB94Ng6, HR6VvY__G, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cI1hBIwZD", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-124eo04", className, classNames)} data-framer-name={"Default"} initial={variant} layoutDependency={layoutDependency} layoutId={"cI1hBIwZD"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: SJkB94Ng6, borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter-SemiBold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-font-weight": "600", "--framer-letter-spacing": "0.05em", "--framer-line-height": "24px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(82, 82, 82))"}}>SECTION NAME</motion.p></React.Fragment>} className={"framer-yavtkn"} data-framer-name={"Subheading"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"lxwikFSdL"} style={{"--extracted-r6o4lv": "rgb(82, 82, 82)", "--framer-paragraph-spacing": "16px"}} text={HR6VvY__G} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-nJWwn[data-border=\"true\"]::after, .framer-nJWwn [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nJWwn.framer-un5ebu, .framer-nJWwn .framer-un5ebu { display: block; }", ".framer-nJWwn.framer-124eo04 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 2px 12px 1px 12px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-nJWwn .framer-yavtkn { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nJWwn.framer-124eo04 { gap: 0px; } .framer-nJWwn.framer-124eo04 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-nJWwn.framer-124eo04 > :first-child { margin-top: 0px; } .framer-nJWwn.framer-124eo04 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 124
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"SJkB94Ng6":"bGCOLOR","HR6VvY__G":"label"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerDT9isorhv: React.ComponentType<Props> = withCSS(Component, css, "framer-nJWwn") as typeof Component;
export default FramerDT9isorhv;

FramerDT9isorhv.displayName = "Section-Badge";

FramerDT9isorhv.defaultProps = {height: 27, width: 124};

addPropertyControls(FramerDT9isorhv, {SJkB94Ng6: {defaultValue: "var(--token-afc428eb-83f4-4256-aa82-c07d2f223ccd, rgb(245, 245, 245)) /* {\"name\":\"Neutral-100\"} */", title: "BG COLOR", type: ControlType.Color}, HR6VvY__G: {defaultValue: "SECTION NAME", displayTextArea: false, title: "Label", type: ControlType.String}} as any)

addFonts(FramerDT9isorhv, [])